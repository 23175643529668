import React, { useContext } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { layoutMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import Popovers from '../../../components/bootstrap/Popovers';
import Logo from '../../../components/Logo';
import ThemeContext from '../../../contexts/themeContext';

const DefaultHeader = () => {
	const { width } = useDeviceScreen();
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	return (
		<Header>
			<HeaderLeft>
				{!asideStatus &&



					<Logo height={74} />

				}
			</HeaderLeft>
			<HeaderRight>

			</HeaderRight>
		</Header>
	);
};

export default DefaultHeader;
