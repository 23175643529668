import axios from "axios";
import * as actions from "../api";
 
import apiUrl from "../../config.json";

const api = ({ dispatch }) => (next) => async (action) => {

  if (action.type !== actions.apiCallBegan.type)
    return next(action);
  // console.log(action);
  // if (action.type === actions.apiNonCallChange.type) {
  //  console.log(action);
  //  const {data,onSuccess} = action.payload;
  //   return dispatch({ type: onSuccess,payload: data});
  //   }

  const {
    url,
    method,
    data,
    onStart,
    onSuccess,
    onError,
    DBActionName,
  } = action.payload;

  //console.log(action.payload);

  if (onStart) dispatch({ type: onStart });

  next(action);

  let token = localStorage.getItem("token2");
  token = token.replace("b'","").replace("'","");


  if (DBActionName === "searchMsg") {
    try {
      const response = await axios.request({
        baseURL: apiUrl.apiUrl,
        url,
        method,
        data,
        headers: {
          Authorization: `Basic ${token}`,
        },
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
      });
      console.log(response.data);
      // General
      dispatch(actions.apiCallSuccess(response.data));

      // Specific
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
      // let linkcome = response.data.next.replace(apiUrl.apiUrl, ""); 
      // do {

      //    if (response.data.next !== null) {

      //      const response = await axios.request({
      //        baseURL: apiUrl.apiUrl,
      //        url: linkcome,
      //        method,
      //        data,
      //        headers: {
      //          Authorization: `Basic ${token}`,
      //        },
      //        maxContentLength: 100000000,
      //        maxBodyLength: 1000000000,
      //      });
      //      console.log(response.data);
      //      // General
      //      dispatch(actions.apiCallSuccess(response.data));
      //       linkcome = response.data.next; 
      //      // Specific
      //      if (onSuccess)
      //        dispatch({ type: onSuccess, payload: response.data });
      //    }
      // } while (response.data.next !== null);


    } catch (error) {
      if (error.response) {
      } else {
        dispatch(actions.apiCallFailed(error.response));
      }

      if (onError)
        dispatch({ type: onError, payload: { error: error.response } });
    }
  }
  else if (url !== "token") {
    try {
      const response = await axios.request({
        baseURL: apiUrl.apiUrl,
        url,
        method,
        data,
        headers: {
          Authorization: `Basic ${token}`,
        },
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
      });
      console.log(response.data);
      // General
      dispatch(actions.apiCallSuccess(response.data));

      // Specific
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
      if (error.response) {
      } else {
        dispatch(actions.apiCallFailed(error.response));
      }

      if (onError)
        dispatch({ type: onError, payload: { error: error.response } });
    }
  } else {
    try {
      const response = await axios.request({
        baseURL: apiUrl.apiUrl,
        url,
        method,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      // General
      dispatch(actions.apiCallSuccess(response.data));

      // Specific
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
      if (error.response) {
      } else {
        dispatch(actions.apiCallFailed(error.response));
      }

      if (onError)
        dispatch({ type: onError, payload: { error: error.response } });
    }
  }



};

export default api;