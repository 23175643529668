import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import React, {useContext } from 'react';
import AuthContext from "../contexts/authContext";
const slice = createSlice({
  name: "user",

  initialState: {
    loading:false,
    issuccesslogin:false,
    user: "",
    token: "",
    token2: "",
    wa: "",
    error:"",
    id:"",
  },
  reducers: {
    // action => action handler
    RequesAuth: (user, action) => {

      if (action.payload.token){
        user.token = action.payload.token;
        user.wa = action.payload.wa;
        user.token2 = action.payload.token2;
        user.user = action.payload.username;
        user.issuccesslogin=true;
        localStorage.setItem('token', user.token);
        localStorage.setItem('token2', user.token2);

      }else {
        user.error = action.payload.error.data.error;
        user.issuccesslogin = false;
      }
    },
    RequesCheckAuth: (user, action) => {
      console.log(action);
      if (action.payload.data.token) {
        user.token = action.payload.data.token;
        user.wa = action.payload.data.wa;
        user.token2 = action.payload.data.token2;
        user.user = action.payload.data.username;
        user.issuccesslogin = true;
        localStorage.setItem('token', user.token);
        localStorage.setItem('token2', user.token2);

      } else {
        user.error = action.payload.data.error;
     
        user.issuccesslogin = false;
        user.token = "";
        user.wa = "";
        user.token2 = "";
        user.user = "";
        localStorage.removeItem('token');
        localStorage.removeItem('token2');
        localStorage.removeItem('Whatsbot_authUsername');
        user.loading = false;
      }
    },
    RequestStart: (user, action) => {
      user.loading = true;
    },
    RequestFinish: (user, action) => {
      user.loading = false;
    },
    Requestlogout: (user, action) => {
      user.error = "";
      user.issuccesslogin = false;
      user.token = "";
      user.wa = "";
      user.token2 = "";
      user.user = "";
      localStorage.removeItem('token');
      localStorage.removeItem('token2');
      localStorage.removeItem('Whatsbot_authUsername');
      user.loading = false;
    },
    RequestFailed: (user, action) => {
      const { setUser } = useContext(AuthContext);
      user.error = action.payload.error.data.error;
      user.issuccesslogin = false;
      user.token = "";
      user.wa = "";
      user.token2 = "";
      user.user = "";
      setUser
      localStorage.removeItem('token');
      localStorage.removeItem('token2');

      user.loading = false;
    },
  },
});

export const {
 
  RequestStart,
  RequestFailed,
  RequestFinish,
  RequesAuth,
  Requestlogout,
  RequesCheckAuth,
} = slice.actions;

export default slice.reducer;
const apiEndpoint =  "token";

export const CheckUserTokenServer = (data:any) => {
  (dispatch: Dispatch) => {
    return dispatch(
      RequesCheckAuth(data)
    );
  };
};

export const logout = () => {
   (dispatch:Dispatch) =>{
     return  dispatch(
      Requestlogout({})
    );
  };
};
// export const login = (data) => (dispatch, getState) => {
 
//   const auth =JSON.stringify({"username": data.data.username,
//   "password": data.data.password});
//  console.log(auth);
//   return dispatch(
//     apiCallBegan({
//       url: apiEndpoint,
//       method: "post",
//       data: auth,
//       onStart: RequestStart.type,
//       onSuccess: userAuth.type,
//       onError: RequestFailed.type,
//     })
//   );
// };

export const CheckUserInfo = (data:any) => (dispatch:Dispatch) => {
  // const encodedString = Buffer.from(
  //   data.username + ":" + data.password,
  //   "utf8"
  // ).toString("base64");
  return dispatch(
    apiCallBegan({
      url: apiEndpoint,
      method: "post",
      data: data,
      onStart: RequestStart.type,
      onSuccess: RequesAuth.type,
      onError: RequestFailed.type,
    })
  );
};

export const getUserAuth = createSelector(
  (state:any) => state.entities.user,

  (user) => user,
);
export const getUser = createSelector(
  (state:any) => state.entities.user,
  (user)=> user
  );
 


